import React from 'react';

import {
  Layout, Typography, Separator,
} from '../components';

const MentionsLegales = () => (
  <Layout
    title="Politique de confidentialité - Docteur Amir Ghazanfari"
    index={false}
    follow={false}
    headerStyle="white"
  >
    <div className="px-4 py-12 bg-white">
      <div className="flex flex-col mx-auto space-y-12 max-w-screen-mdx">
        <Typography className="text-center uppercase tracking-[0.3em]" size="xlg" weight="bold">
          Mentions légales
        </Typography>
        <div className="flex flex-col space-y-3">
          <div className="flex flex-col space-y-1">
            <Typography size="mdxl" weight="bold">
              Qui sommes-nous ?
            </Typography>
            <Separator />
          </div>
          <Typography>
            L&apos;adresse de notre site Web est :
            {' '}
            <a className="underline text-link hover:text-primary" href="https://docteurghazanfari.com" target="_blank" rel="noreferrer">https://docteurghazanfari.com</a>
            .
          </Typography>
        </div>
      </div>
    </div>
  </Layout>
);

export default MentionsLegales;
